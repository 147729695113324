<template>
  <el-card class="v_user_list">
    <div class="g-flex"  slot="header">
      <span>银行卡列表</span>
      <div class="g-flex-justify-end g-flex-1">
        <el-button size="mini" type="success" @click="setForm({}, 1)">添加银行</el-button>
      </div>
    </div>
    <!-- 搜索 -->
    <!-- <el-form :inline="true" size="mini" :model="query">
      <el-form-item label="平台类型">
        <el-select v-model="query.platform_id" @change="getList()">
          <el-option :label="item.title" :value="item.id" v-for="(item, index) in platFormList" :key="index" ></el-option>
        </el-select>
      </el-form-item>
    </el-form> -->

    <!-- 表格 -->
    <el-table stripe size="mini" v-loading="table.loading" :data="table.rows" border>
      <el-table-column label="id">
          <template slot-scope="scope">
              <span>{{scope.row.id}}</span>
          </template>
      </el-table-column>
      <el-table-column label="银行名称" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span>{{ scope.row.bank_name }}</span>
          </template>
      </el-table-column>
      <el-table-column label="状态" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span class="g-red" v-if="scope.row.status == 0">禁用</span>
            <span class="g-green" v-if="scope.row.status == 1">正常</span>
          </template>
      </el-table-column>
      <el-table-column label="创建时间" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span>{{ formatDate(scope.row.create_time,'yyyy-MM-dd hh:mm:ss') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="更新时间" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span>{{ formatDate(scope.row.modify_time,'yyyy-MM-dd hh:mm:ss') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="setForm(scope.row, 2)">编辑</el-button>
          <el-button size="mini" type="danger" @click="apiDelBankHandel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>


    <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    
    <bank-list-pop ref="BankListPop" @emitSaveSuccess="getList"/>

  </el-card>
</template>

<script>
  import { formatDate } from '@/utils/index.js';
  import { apiGetBankList, apiDelBank } from '@/utils/api.js'
  import BankListPop from './BankListPop.vue'
  export default {
    components: { BankListPop },
    data() {
      return {
        auth:{
          addBlack: this.$global.ApiList.includes(60),
          delBlack: this.$global.ApiList.includes(61),
        },
        platFormList: [],

        query: { search_key: 'user_id', search_val: '', platform_id: '',  page:1, limit: 15 },
        table:{ loading:false, total:0, rows:[] },
        formData: {},
      }
    },
    async created() {
      this.getList()
    },
    filters: {
      filtersSex(sex) {
        if(sex == 1) return '男'
        return '女'
      },
      filtersPlatName(id, list) {
      let obj = list.find(item => item.id == id)
      if(obj) return obj.title
      return ''
    },
    },
    methods: {
      formatDate,

      // 删除银行卡
      async apiDelBankHandel(item) {
        this.$confirm('此操作将会删除该银行卡, 是否继续?', '删除银行卡', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.table.loading = true
          const { success, data } =  await apiDelBank(item).catch(() =>  this.table.loading = false)
          this.table.loading = false
          if(!success) return
          this.$message.success(data.msg)
          this.getList()
        }).catch(() => {});
      },

      async getList(pageInit = true) {
        if (pageInit) this.query.page = 1
        this.table.loading = true
        const { success, data } = await apiGetBankList(this.query).catch(() => this.table.loading = false)
        if(!success) return
        this.table.loading = false
        this.table.rows = data.rows
        this.table.total = data.total
      },

      //每页数量改变
      sizeChange(limit) {
        this.query.limit = limit
        this.getList()
      },

      //设置form
      setForm(formData = { id: ''  }, status = 1) {
        if(!formData.id) return this.$refs.BankListPop.onShow({ id: '' }, status)
        this.formData = JSON.parse(JSON.stringify(formData))
        return this.$refs.BankListPop.onShow(this.formData, status)
      },

      // 点击重置
      resetData() {
        this.query = { search_key: 'user_id', search_val: '', platform_id: 'ALL',  page:1, limit: 15 }
        this.getList()
      },
      
    }
  }
</script>

<style lang="scss">
</style>